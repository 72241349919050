<svg
  class="background"
  xmlns="http://www.w3.org/2000/svg"
  width="1486"
  height="751"
  fill="none"
  viewBox="0 0 1486 751"
  style="enable-background: new 0 0 1486 751; z-index: -1"
  preserveAspectRatio="xMidYMid slice"
>
  <g clip-path="url(#a)">
    <path stroke="white" d="M179.984 797.009c37-73.5 141.8-225.2 265-244 154-23.5 334-238 314.5-551" opacity=".4" />
    <path stroke="white" d="M136.484 797.009c37-73.5 141.8-225.2 265-244 154-23.5 334-238 314.5-551" opacity=".4" />
    <path stroke="white" d="M223.484 797.009c37-73.5 141.8-225.2 265-244 154-23.5 334-238 314.5-551" opacity=".4" />
    <path stroke="white" d="M50.984 797.009c37-73.5 141.8-225.2 265-244 154-23.5 334-238 314.5-551" opacity=".4" />
    <path stroke="white" d="M7.484 797.009c37-73.5 141.8-225.2 265-244 154-23.5 334-238 314.5-551" opacity=".4" />
    <path stroke="white" d="M94.484 797.009c37-73.5 141.8-225.2 265-244 154-23.5 334-238 314.5-551" opacity=".4" />
    <path
      fill="white"
      fill-rule="evenodd"
      d="M-434.015 153.716c0-157.083 84.686-293.975 210-365.707v36.707h454.724c95.186 76.734 156.276 195.585 156.276 329 0 231.407-183.787 419-410.5 419s-410.5-187.593-410.5-419Z"
      clip-rule="evenodd"
      opacity=".2"
    />
    <path
      fill="white"
      fill-rule="evenodd"
      d="M-434.017 124.432c0-141.068 76.053-264.004 188.591-328.423v32.964H162.94c85.482 68.911 140.343 175.646 140.343 295.459 0 207.816-165.05 376.284-368.65 376.284-203.599 0-368.65-168.468-368.65-376.284Z"
      clip-rule="evenodd"
      opacity=".2"
    />
    <path
      fill="white"
      fill-rule="evenodd"
      d="M-434.016 85.285c0-124.253 66.987-232.536 166.111-289.277v29.036h359.69c75.291 60.697 123.614 154.709 123.614 260.24 0 183.045-145.377 331.432-324.708 331.432-179.331 0-324.707-148.387-324.707-331.431Z"
      clip-rule="evenodd"
      opacity=".2"
    />
    <path
      fill="white"
      fill-rule="evenodd"
      d="M1272.48 827.009c226.72 0 410.5-187.593 410.5-419 0-231.408-183.78-419-410.5-419-226.71 0-410.496 187.592-410.496 419 0 231.407 183.786 419 410.496 419Zm115.5-136h-242v65h242v-65Z"
      clip-rule="evenodd"
      opacity=".2"
    />
    <path
      fill="white"
      fill-rule="evenodd"
      d="M1314.34 813.575c203.6 0 368.65-168.467 368.65-376.283S1517.94 61.008 1314.34 61.008c-203.6 0-368.654 168.468-368.654 376.284s165.054 376.283 368.654 376.283Zm103.72-132.329h-217.33v58.373h217.33v-58.373Z"
      clip-rule="evenodd"
      opacity=".2"
    />
    <path
      fill="white"
      d="M1682.99 476.44c0 183.044-145.38 331.431-324.71 331.431-179.33 0-324.71-148.387-324.71-331.431 0-183.045 145.38-331.431 324.71-331.431 179.33 0 324.71 148.386 324.71 331.431Z"
      opacity=".2"
    />
  </g>
</svg>

<!-- <svg
  class="background"
  xmlns="http://www.w3.org/2000/svg"
  width="1486"
  height="751"
  fill="none"
  viewBox="0 0 1486 751"
  style="enable-background: new 0 0 1486 751"
  preserveAspectRatio="xMidYMid slice"
>
  <g clip-path="url(#a)">
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1134.73 489.627C1072.87 585.393 989.259 645.009 912.075 656.787C850.641 666.162 793.738 708.689 747.54 758.122C701.352 807.545 665.928 863.804 647.446 900.518L646.553 900.068C665.071 863.282 700.547 806.941 746.81 757.439C793.062 707.947 850.158 665.224 911.924 655.799C988.741 644.077 1072.13 584.693 1133.89 489.084C1195.65 393.489 1235.74 261.721 1226 105.324L1227 105.262C1236.76 261.865 1196.6 393.847 1134.73 489.627Z"
      fill="white"
    />
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1091.23 489.627C1029.37 585.393 945.759 645.009 868.575 656.787C807.141 666.162 750.238 708.689 704.04 758.122C657.852 807.545 622.428 863.804 603.946 900.518L603.053 900.068C621.571 863.282 657.047 806.941 703.31 757.439C749.562 707.947 806.658 665.224 868.424 655.799C945.241 644.077 1028.63 584.693 1090.39 489.084C1152.15 393.489 1192.24 261.721 1182.5 105.324L1183.5 105.262C1193.26 261.865 1153.1 393.847 1091.23 489.627Z"
      fill="white"
    />
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1178.23 489.627C1116.37 585.393 1032.76 645.009 955.575 656.787C894.141 666.162 837.238 708.689 791.04 758.122C744.852 807.545 709.428 863.804 690.946 900.518L690.053 900.068C708.571 863.282 744.047 806.941 790.31 757.439C836.562 707.947 893.658 665.224 955.424 655.799C1032.24 644.077 1115.63 584.693 1177.39 489.084C1239.15 393.489 1279.24 261.721 1269.5 105.324L1270.5 105.262C1280.26 261.865 1240.1 393.847 1178.23 489.627Z"
      fill="white"
    />
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1005.73 489.627C943.869 585.393 860.259 645.009 783.075 656.787C721.641 666.162 664.738 708.689 618.54 758.122C572.352 807.545 536.928 863.804 518.446 900.518L517.553 900.068C536.071 863.282 571.547 806.941 617.81 757.439C664.062 707.947 721.158 665.224 782.924 655.799C859.741 644.077 943.131 584.693 1004.89 489.084C1066.65 393.489 1106.74 261.721 1097 105.324L1098 105.262C1107.76 261.865 1067.6 393.847 1005.73 489.627Z"
      fill="white"
    />
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M962.232 489.627C900.369 585.393 816.759 645.009 739.575 656.787C678.141 666.162 621.238 708.689 575.04 758.122C528.852 807.545 493.428 863.804 474.946 900.518L474.053 900.068C492.571 863.282 528.047 806.941 574.31 757.439C620.562 707.947 677.658 665.224 739.424 655.799C816.241 644.077 899.631 584.693 961.392 489.084C1023.15 393.489 1063.24 261.721 1053.5 105.324L1054.5 105.262C1064.26 261.865 1024.1 393.847 962.232 489.627Z"
      fill="white"
    />
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1049.23 489.627C987.369 585.393 903.759 645.009 826.575 656.787C765.141 666.162 708.238 708.689 662.04 758.122C615.852 807.545 580.428 863.804 561.946 900.518L561.053 900.068C579.571 863.282 615.047 806.941 661.31 757.439C707.562 707.947 764.658 665.224 826.424 655.799C903.241 644.077 986.631 584.693 1048.39 489.084C1110.15 393.489 1150.24 261.721 1140.5 105.324L1141.5 105.262C1151.26 261.865 1111.1 393.847 1049.23 489.627Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M0 419C0 187.593 183.787 0 410.5 0C637.213 0 821 187.593 821 419C821 650.407 637.213 838 410.5 838C183.787 838 0 650.407 0 419Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M-0.00311279 389.716C-0.00311279 181.901 165.047 13.4329 368.647 13.4329C572.247 13.4329 737.297 181.901 737.297 389.716C737.297 597.532 572.247 766 368.647 766C165.047 766 -0.00311279 597.532 -0.00311279 389.716Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M-0.000854492 350.569C-0.000854492 167.525 145.376 19.138 324.707 19.138C504.038 19.138 649.414 167.525 649.414 350.569C649.414 533.613 504.038 682 324.707 682C145.376 682 -0.000854492 533.613 -0.000854492 350.569Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M2117 573.293C2117 804.7 1933.21 992.293 1706.5 992.293C1479.79 992.293 1296 804.7 1296 573.293C1296 341.886 1479.79 154.293 1706.5 154.293C1933.21 154.293 2117 341.886 2117 573.293Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M2117 602.576C2117 810.392 1951.95 978.86 1748.35 978.86C1544.75 978.86 1379.7 810.392 1379.7 602.576C1379.7 394.761 1544.75 226.293 1748.35 226.293C1951.95 226.293 2117 394.761 2117 602.576Z"
      fill="white"
    />
  </g>
</svg> -->

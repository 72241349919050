import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconModule } from '@teamfoster/sdk/icon';
import { ImageModule } from '@teamfoster/sdk/image';
import { InViewModule } from '@teamfoster/sdk/in-view';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, ImageModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundComponent {}
